
.AutoSizerWrapper {
    flex: 1 1 auto;
}

/*.List {*/
/*    border: 1px solid #e0e0e0;*/
/*}*/

/*.row {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    padding: 0 25px;*/
/*    background-color: #fff;*/
/*    border-bottom: 1px solid #e0e0e0;*/
/*}*/

.checkboxLabel {
    display: flex;
    align-items: center;
}

.checkbox {
    margin-right: 5px;
}

.List {
    width: 100%;
    border: 1px solid #DDD;
    margin-top: 15px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 25px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

.row {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 25px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

.letter {
    display: inline-block;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 40px;
    color: white;
    font-size: 1.5em;
    margin-right: 25px;
}

.name {
    font-weight: bold;
    margin-bottom: 2px;
}

.index {
    color: #37474f;
}

.height {
    flex: 1;
    text-align: right;
    color: #bdbdbd;
    font-size: .75em;
    font-weight: 100;
}

.noRows {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bdbdbd;
}

.checkboxLabel {
    margin-left: .5rem;
}

.checkboxLabel:first-of-type {
    margin-left: 0;
}

.checkbox {
    margin-right: 5px;
}

.isScrollingPlaceholder {
    color: #DDD;
    font-style: italic;
}

.WindowScrollerWrapper {
    flex: 1 1 auto;
}

.List {
    border: 1px solid #e0e0e0;
}

.rowScrolling::after {
    content: ': scrolling';
    font-size: 0.65rem;
    color: #aaa;
}

#react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

#react-paginate li {
    display: inline-block;
}